import "./ComingSoon.css";

import TopNavBar from "../../components/TopNavBar";
import Sidebar from "../../components/Sidebar";

const ComingSoon = () => {
    return (
        <>
          <div className="navBarWrapper">
            <Sidebar />
            {/* <==> to homecontainer */}
            <div className="contentWrapper">
              <TopNavBar />
    <div className="coming-soon-container">
      <h1>Coming Soon</h1>
      <p>We're launching something awesome. Stay tuned!</p>
    </div></div>
      </div>
    </>
  );
};

export default ComingSoon;
