import TopNavBar from "../../components/TopNavBar";
import Sidebar from "../../components/Sidebar";

const Dashboard = () => {
    return (
        <>
          <div className="navBarWrapper">
            <Sidebar />
            <div className="contentWrapper">
              <TopNavBar />
              <div className="iframe-container">
                <iframe 
                  src="https://axzoragroup.dp.dataplant.ai/home/seed-list?embedMode=True" 
                  title="Dashboard"
                  width="100%" 
                  height="100%"
                  style={{ border: "none", minHeight: "80vh" }}
                />
              </div>
            </div>
          </div>
        </>
    );
};

export default Dashboard;

// import React, { useEffect } from "react";

// const ExternalPage = () => {
//   useEffect(() => {
//     window.open("https://axzoragroup.dp.dataplant.ai/home/seed-list?embedMode=True", "_blank");
//   }, []);
//   return (
//     <>
//       <div className="navBarWrapper">
//         <Sidebar />
//         {/* <==> to homecontainer */}
//         <div className="contentWrapper">
//           <TopNavBar />
// <div className="coming-soon-container">
//   <h1>Dashboard</h1>
//   <p>Redirecting...</p>
// </div></div>
//   </div>
// </>
// );
// };

// export default ExternalPage;


// import React, { useEffect } from "react";
// import axios from "axios";

// const ExternalPage = () => {
//   useEffect(() => {
//     const loginAndRedirect = async () => {
//       try {
//         const response = await axios.post("https://axzoragroup.dp.dataplant.ai/api/login", {
//           username: "success@thedataplant.com",
//           password: "Dataplant@2024",
//         });

//         if (response.data.access) {
//           localStorage.setItem("DATAPLANT_USER", response.data.access);

//           window.location.href = "https://axzoragroup.dp.dataplant.ai/home/seed-list?embedMode=True";
//         } else {
//           console.error("Login failed: No token received.");
//         }
//       } catch (error) {
//         console.error("Login failed:", error);
//       }
//     };

//     loginAndRedirect();
//   }, []);

//   return <p>Logging in and redirecting...</p>;
// };

// export default ExternalPage;

