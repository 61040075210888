import React, { useContext, useState } from "react";
import SideIcon from "../../src/assets/png/c162_right_alignment.png";
import { ReactComponent as MeetingsRoomIcon } from "../../src/assets/svg/MeetingsRoomIcon.svg";
import { ReactComponent as CoachesIcon } from "../../src/assets/svg/CoachesIcon.svg";
import { ReactComponent as BookingRequestsIcon } from "../../src/assets/svg/BookingRequestsIcon.svg";
import { ReactComponent as NewsIcon } from "../../src/assets/svg/NewsIcon.svg";
import { ReactComponent as SettingsIcon } from "../../src/assets/svg/SettingsIcon.svg";
import { ReactComponent as StaffIcon } from "../../src/assets/svg/StaffIcon.svg";
import { ReactComponent as UserRequestIcon } from "../../src/assets/svg/userreq.svg";
import { ReactComponent as PolicyIcon } from "../../src/assets/svg/policy.svg"
import { ReactComponent as EventsIcon } from "../../src/assets/svg/event_icon_black.svg";
import { ReactComponent as AssetsIcon } from "../../src/assets/svg/assets.svg";
import { ReactComponent as SurveyIcon } from "../../src/assets/svg/surveyform.svg";
import { ReactComponent as DashboardIcon } from "../../src/assets/svg/dashboard.svg";
import AddModeratorIcon from '@mui/icons-material/AddModerator';

import { Link } from "react-router-dom";
import "./Sidebar.css";
import UserRequests from "../pages/userrequests/UserRequests";

function Sidebar() {

  const linksItems = [
    {
      id: 1,
      to: "/dashboard",
      linkText: "Dashboard",
      icon: <DashboardIcon />,
      subMenu: [
        { id: "1-1", to: "/dashboard/listview", linkText: "List View" },
        { id: "1-2", to: "/dashboard/view360", linkText: "View 360" },
        { id: "1-3", to: "/dashboard/Insights", linkText: "Insights" },
      ],
    },
    {
      id: 2,
      to: "/meetingrooms",
      linkText: "Meeting Rooms",
      icon: <MeetingsRoomIcon />,
    },
    {
      id: 3,
      to: "/coaches",
      linkText: "Coaches",
      icon: <CoachesIcon />,
    },
    {
      id: 4,
      to: "/bookingrequests",
      linkText: "Booking Requests",
      icon: <BookingRequestsIcon />,
    },
    {
      id: 5,
      to: "/review-moderation",
      linkText: "Review Moderation",
      icon: <AddModeratorIcon />,
    },
    {
      id: 6,
      to: "/news",
      linkText: "News",
      icon: <NewsIcon />,
    },
    {
      id: 7,
      to: "/settings",
      linkText: "Settings",
      icon: <SettingsIcon />,
    },
    {
      id: 8,
      to: "/staff",
      linkText: "Staff",
      icon: <StaffIcon />,
    },
    {
      id: 9,
      to: "/userrequests",
      linkText: "User Requests",
      icon: <UserRequestIcon />,
    },
    {
      id: 10,
      to: "/policy",
      linkText: "Policy",
      icon: <PolicyIcon />
    },
    {
      id: 11,
      to: "/events",
      linkText: "Events",
      icon: <EventsIcon />,
    },
    {
      id: 12,
      to: "/comingsoon",
      linkText: "Assets",
      icon: <AssetsIcon />,
    },
    {
      id: 13,
      to: "/comingsoon",
      linkText: "Survey",
      icon: <SurveyIcon />,
    },
  ];

  const [selectedLink, setSelectedLink] = useState(
    localStorage.getItem("selectedLink") || ""
  );

  const [openSubMenuId, setOpenSubMenuId] = useState(null);

  const handleLinkClick = (link) => {
    setSelectedLink(link.id.toString());
    localStorage.setItem("selectedLink", link.id.toString());

    if (link.subMenu) {
      setOpenSubMenuId(openSubMenuId === link.id ? null : link.id);
    } else {
      setOpenSubMenuId(null);
    }
  };
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleIconClick = () => {
    setIsCollapsed(!isCollapsed);
    console.log("animation for sidebar", isCollapsed)
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData?.email;
  const allowedEmails = [
    'support@axzoragroup.com'
  ];


  return (
    <>
      <div className={`sideBarLinksWrapper ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="sideIconsection" id="linksToggleId" onClick={handleIconClick}>
          <img src={SideIcon} alt="" />
        </div>
        <div className="linksWrapper">
        {linksItems.map((link) => (
          allowedEmails.includes(userEmail) || link.id !== 9 ? (
            <div key={link.id} className="menu-item">
              <Link
                to={link.subMenu ? "#" : link.to}
                onClick={() => handleLinkClick(link)}
                className={`sideBarLink ${selectedLink === link.id.toString() ? "selected" : ""}`}
                id="navigationLinksId"
              >
                <div className="linkTxtIconClass">{link.icon}</div>
                <div className="linkIconClass"><p>{link.linkText}</p></div>
              </Link>

              {openSubMenuId === link.id && link.subMenu && (
                <div className="submenu">
                  {link.subMenu.map((subLink) => (
                    <Link key={subLink.id} to={subLink.to} className="subMenuItem">
                      {subLink.linkText}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ) : null
        ))}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
