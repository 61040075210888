import axios from "axios";
import { useEffect, useRef, useState } from "react";
import "./NotificationWidget.css";
import BellIcon from "../../../src/assets/png/BellIcon.png";
import { ReactComponent as NotificationWatch } from "../../../src/assets/svg/notificationWatch.svg";

const NotificationsView = ({
  notifications,
  setNotifications,
  setNotificationNumber,
}) => {
  const notificationRefs = useRef({});
  const notificationsMap = useRef(
    new Map(
      notifications.map((notification) => [notification.id, notification])
    )
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const notificationId = entry.target.getAttribute("data-id");
          if (
            entry.isIntersecting &&
            !notificationsMap.current.get(notificationId)?.isRead
          ) {
            markNotificationAsRead(notificationId);
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.values(notificationRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, [notifications]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const markNotificationAsRead = async (notificationId) => {
    if (notificationsMap.current.get(notificationId)?.isRead) {
      return;
    }
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/notifications/mark-as-read/${notificationId}`
    );
    if (response?.status === 200) {
      setNotifications((prev) =>
        prev.map((notification) => {
          if (Number(notification?.id) === Number(notificationId)) {
            return { ...notification, isRead: true };
          }
          return notification;
        })
      );
      setNotificationNumber((prev) => Math.max(0, prev - 1));
      notificationsMap.current.set(notificationId, {
        ...notificationsMap.current.get(notificationId),
        isRead: true,
      });
    }
  };

  const getNotificationTime = (createdAt) => {
    const date = new Date(createdAt);
    const currentTime = new Date();
    const timeDifference = currentTime - date;
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    if (hoursDifference < 24) {
      return `${hoursDifference}hrs`;
    } else {
      const daysDifference = Math.floor(hoursDifference / 24);
      return `${daysDifference}d`;
    }
  };
  const clearNoificationHandler = async () => {
    try {
      setIsLoading(true);
      const adminDetails = localStorage.getItem("userData");
      const parsedData = JSON.parse(adminDetails);
      const notificationElements = Object.values(notificationRefs.current);
      notificationElements.forEach((element, index) => {
        if (element) {
          element.style.animationDelay = `${index === 0 ? 0 : index * 0.15}s`;
          element.classList.add("fading-out");
        }
      });
      setTimeout(async () => {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/v1/notifications/${parsedData?.id}`
        );
        if (response?.status === 200) {
          setNotifications([]);
          setNotificationNumber(0);
        }
      }, notificationElements.length * 200);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="notificationTextWrapper show">
        <div className="notificationBar">
          <h3>Notifications</h3>
        </div>
        <div className="notifi-container">
          {notifications.length > 0 ? (
            notifications.map((note) => (
              <div
                key={note.id}
                className={`notificationClass`}
                data-id={note.id}
                ref={(el) => (notificationRefs.current[note.id] = el)}
              >
                <div className="notificationProfileImg">
                  <img
                    src={note?.profileImage}
                    alt=""
                    className="notification-img"
                  />
                </div>
                <div className="notificationDetails showDrawer">
                  <div className="usernameDiv">
                    <h2>{note?.username}</h2>
                  </div>
                  <div className="messageDiv">
                    <p>{note?.message}</p>
                    <div className="notification-countdown">
                      <p>{getNotificationTime(note?.createdAt)}</p>
                      {!note.isRead ? (
                        <div className="notification-unread-dot"></div>
                      ) : (
                        <div className="notification-read-dot"> </div>
                      )}
                    </div>
                  </div>
                  <div className="notificationTimeClass">
                    <div className="notifWatch">
                      <NotificationWatch id="watch-svg" />
                    </div>
                    <p>{formatDate(note.createdAt)} </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-notifications"> No Notifications Available</div>
          )}
        </div>
        {notifications?.length > 0 && (
          <div
            className="notification-clear-all"
            onClick={clearNoificationHandler}
            disabled={isLoading}
          >
            Clear All
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsView;
