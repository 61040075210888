import TopNavBar from "../../components/TopNavBar";
import Sidebar from "../../components/Sidebar";

const Listview = () => {
    return (
        <>
          <div className="navBarWrapper">
            <Sidebar />
            <div className="contentWrapper">
              <TopNavBar />
              <div className="iframe-container">
                <iframe 
                  src="https://axzoragroup.dp.dataplant.ai/home/seed-list?embedMode=True" 
                  title="Listview"
                  width="100%" 
                  height="100%"
                  style={{ border: "none", minHeight: "80vh" }}
                />
              </div>
            </div>
          </div>
        </>
    );
};

export default Listview;